
import { Navigate } from 'react-router-dom';
import { auth_service } from 'src/authService';

export const PrivateRoute = ({ element, ...rest }) => {
    if (!auth_service.isAuthenticated()) {
        return <Navigate to="/login" />
    }
    if(auth_service.isAuthenticated() && auth_service.getPermission(rest?.permissionName)) {
        return element
    }
    return <Navigate to="/no-permission" />;
};

export const PublicRoute = ({ element }) => {
    return element;
};

export const ProtectedRoute = ({ element }) => {
    return element;
};

