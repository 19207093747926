/* auth page work for newsepick BO */
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { Apis } from 'src/config';

const isAuthenticated = () => {
  const refreshToken = getRefreshToken();
    const data = getUserDetails();
    if (refreshToken === null || refreshToken === "" || refreshToken === undefined) {
        clearData();
        return false;
    } else {
        return data !== null;
    }
};

const setSessionData = (authResult, response) => {
  localStorage.setItem('userData', JSON.stringify(authResult?.user));
  localStorage.setItem('userToken', JSON.stringify(authResult?.userToken));
  localStorage.setItem('refreshToken', JSON.stringify(authResult?.refreshToken));
  setRefreshToken(response.headers['authorization']);
}

const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
}

const setRefreshToken = (refreshToken) => {
  localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
}

const clearData = () => {
  localStorage.removeItem('userData');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userToken');
}

const getUserDetails = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return userData;
};

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    const decodedToken = jwt_decode(user.token);
    if (decodedToken.exp * 1000 < Date.now()) {
      // localStorage.removeItem('user');
      // window.location.reload();
    }
    return decodedToken;
  }
  return null;
}

const logout = () => {
  return new Promise((resolve, reject) => {
      axios({
          method: 'post',
          url: `${Apis.AdminUserLogout}`,
          headers: {'Content-Type': 'application/json'},
          withCredentials: true
      }).then(() => {
          clearData();
          resolve();
      }).catch((e) => {
          console.log(e);
          reject(e);
      });
  });

}

const getUserDetailsFromLocalStorage = () => {
      let userData = JSON.parse(localStorage.getItem('userData'));
      if (userData === undefined || userData === null) {
          return null;
      }
      return userData;
}

const getPermission = (permissionData) => {
  let permissionList = getUserDetailsFromLocalStorage()?.permission;
  let permissions = permissionList;
  if (permissions == null || permissionData === '' || permissions.includes("SUPER_ADMIN")) {
    return true;
  }
  return permissions.includes(permissionData);
}

export const auth_service = { isAuthenticated, logout, getUserDetails,getPermission, getUserDetailsFromLocalStorage, getCurrentUser, setSessionData, clearData, getRefreshToken };










