import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { PublicRoute } from './routes/routesConfig'

const loading = (<div className="pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>)

/*Containers */
const DefaultLayout = React.lazy(() => import('./layout'))
const Login = React.lazy(() => import('./views/login'))
const Logout = React.lazy(() => import('./views/Register/Logout'))

const App = () => {

  return (
    <Router>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/login" name="Login" element={<PublicRoute element={<Login />} />} />
          <Route path="/logout" name="Login" element={<PublicRoute element={<Logout />} />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </Router>
  )

}

export default App;
