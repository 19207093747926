const API_URL = process.env.REACT_APP_BACKEND_API;
const ML_URL = process.env.REACT_APP_recommendServiceURL

const Apis = {

    AdminUserLogin: `${API_URL}/adminUser/login`,
    AdminUserLogout: `${API_URL}/adminUser/logout`,

    /*sign up Code APIS */
    CreateInstituteSignUpCode: `${API_URL}/institute/create-code`,
    SignUpCodeList: (data) => `${API_URL}/institute/code-list?page=${data.page}&size=${data.size}`,
    UpdateSignUpCodeStaus: `${API_URL}/institute/update-code-status`,
    ResendInstituteSignUpCode: (id) => `${API_URL}/institute/resend-code?id=${id}`,
    RegenerateInstituteSignUpCode: (id) => `${API_URL}/institute/regenerate-code?id=${id}`,

    /* Institution summary API */
    InstitutionSummaryList: (data) => `${API_URL}/institute/institute-list?searchQuery=${data?.searchQuery}&searchType=${data?.searchType}&page=${data.page}&size=${data.size}`,
    InstituteProfileDetailsById: (id) => `${API_URL}/institute/getById?id=${id}`,
    TaxInformationById: (id) => `${API_URL}/tax-information/get?userId=${id}`,
    // TransactionsList: (data) => `${API_URL}/institute/transaction?userId=${data.userId}&page=${data.page}&size=${data.size}`,
    TransactionsList: (data) => `${API_URL}/transaction/list?userId=${data?.userId}&creditType=${data?.creditType}&userType=${data?.userType}&page=${data?.page}&size=${data?.size}`,


    CandidateDetailsList: (data) => `${API_URL}/institute/candidate?userId=${data.userId}&page=${data.page}&size=${data.size}`,
    DeleteSignUpCode: (id) => `${API_URL}/demo/delete?id=${id}`,
    TaxInfoVerification: `${API_URL}/tax-information/update-tax-status`,
    InstituteAddressVerification: (data) => `${API_URL}/institute/update-institute-address?id=${data.id}&addressStatus=${data.addressStatus}`,
    GetAllClassCategories: (data) => `${API_URL}/institute-class/get-all-class-categories?instituteId=${data.instituteId}&page=${data.page}&size=${data.size}`,
    GetInstituteAdminsData: (instituteId) => `${API_URL}/institute/admin/list?instituteId=${instituteId}`,
    GetInstituteRequests: (data) => `${API_URL}/institute/query/list?page=${data.page}&size=${data.size}&status=${data?.status}`,
    CreateInstituteAssesmentConfig: `${API_URL}/institute/update-auto-registration`,
    GetInstituteAssesmentConfig: (instituteId) => `${API_URL}/institute/get-auto-registration?instituteId=${instituteId}`,
    UpdateInstituteQueryStatus: `${API_URL}/institute/query/update/status`,
    GetAllActiveInstituteList: `${API_URL}/institute/active`,

    GetQuizSummaryData: (data) => `${API_URL}/quiz/summary/list?instituteId=${data?.instituteId}&filter=${data?.filter}&year=${data?.year}&page=${data?.page}&size=${data?.size}`,
    GetQuizSummaryClassWise: (data) => `${API_URL}/quiz/summary/classwise/list?instituteId=${data?.instituteId}&studentClass=${data?.studentClass}&year=${data?.year}&searchType=${data?.searchType}&searchQuery=${data?.searchQuery}&page=${data?.page}&filter=${data?.filter}&size=${data?.size}`,
    GetQuizSummaryByUserId: (data) => `${API_URL}/quiz/summary/classwise/student/list?instituteId=${data?.instituteId}&userId=${data?.userId}&filter=${data?.filter}&studentClass=${data?.studentClass}&page=${data?.page}&size=${data?.size}`,
    GetReviewQuizList: (data) => `${ML_URL}/users/review?sessionId=${data.sessionId}`,


    //Candidate API
    GetCandidateList: (data) => `${API_URL}/user/list?instituteId=${data.instituteId}&page=${data.page}&size=${data.size}`,
    GetUserDetailsById: (userId) => `${API_URL}/user/get-by-userId?userId=${userId}`,

    //Quiz API
    GetAllQuizDateWise: (data) => `${ML_URL}/users/datewise-quiz-verification?page=${data.page}&size=${data.size}&date=${data.date}`,

    GetCategoryList: `${API_URL}/institute-class/config-categories`,

    //Scrapping Status API
    GetScrappingNews: (data) => `${API_URL}/recommend/recommended/scrapping-summary/?page=${data.page}&size=${data.size}&startDate=${data.startDate}&endDate=${data?.endDate}`,
    GetScrappingCategory: (data) => `${API_URL}/recommend/recommended/category-summary/?startDate=${data.startDate}&endDate=${data?.endDate}`,
    GetScrappingPublications: (data) => `${API_URL}/recommend/recommended/publication-summary/?page=${data?.page}&size=${data?.size}&startDate=${data.startDate}&endDate=${data?.endDate}`,

    GetQuizSummary: (data) => `${ML_URL}/users/get-quiz?page=${data.page}&size=${data.size}&date=${data.date}&status=${data.status}&category=${data.category}`,

    VerifyQuizList: `${ML_URL}/users/quiz-verification`,


    CreateQuizConfiguration: `${API_URL}/quiz-config/create`,
    GetQuizConfigData: (data) => `${API_URL}/quiz-config/list?page=${data.page}&size=${data.size}`,
    GetQuizConfigDataById: (id) => `${API_URL}/quiz-config/get?id=${id}`,
    UpdateQuizConfiguration: `${API_URL}/quiz-config/update`,
    DeleteQuizConfig: (id) => `${API_URL}/quiz-config/delete?id=${id}`,
    CreateUpdateQuizConfig: `${API_URL}/quiz-config/create-update`,

    //Ml Api
    UpdateQuizClassConfig: `${ML_URL}/users/update-quiz-config/`,

    //Coin API
    CreateCoinConfiguration: `${API_URL}/coin/allocation/save`,
    GetCoinConfigData: (data) => `${API_URL}/coin/allocation/list?page=${data.page}&size=${data.size}`,
    GetCoinConfigDataById: (id) => `${API_URL}/coin/allocation/get?id=${id}`,
    UpdateConfigConfiguration: `${API_URL}/coin/allocation/update`,
    DeleteCoinConfig: (id) => `${API_URL}/coin/allocation/delete?id=${id}`,
    GetCoinConfig: `${API_URL}/coin/allocation/config`,
    UpdateCoinConfigStatus: `${API_URL}/coin/allocation/update-status`,
    GetCoinConfigDataByCoinAllocationType: (data) => `${API_URL}/coin/allocation/initial-data?coinAllocationType=${data?.coinAllocationType}&page=${data?.page}&size=${data?.size}`,
    // GetCoinAllocationDetailsByCoinAllocationType: (data) => `${API_URL}/coin/allocation/list?coinAllocationType=${data?.coinAllocationType}&date=${data?.date}`,
    // GetCoinAllocationDetailsByCoinAllocationTypeAndDate: (data) => `${API_URL}/coin/allocation/initial-data-date-wise?coinAllocationType=${data?.coinAllocationType}&date=${data?.date}`,
    GetCoinAllocationDetailsByCoinAllocationTypeAndDate: (data) => `${API_URL}/coin/allocation/list?coinAllocationType=${data?.coinAllocationType}&exactDate=${data?.exactDate}`,
    GetExpireCoinConfigDataByStatus: (data) => `${API_URL}/coin/expiry/config/list?status=${data?.status}&page=${data?.page}&size=${data?.size}`,
    CreateExpireCoinConfigData: `${API_URL}/coin/expiry/config/save`,
    GetIssuedCoinListData: (data) => `${API_URL}/credit/list?coinAllocationType=${data?.coinAllocationType}&page=${data?.page}&size=${data?.size}&search=${data?.search}`,

    //Individual API
    GetIndividualUserList: (data) => `${API_URL}/user/individual-list?searchQuery=${data?.searchQuery}&searchType=${data?.searchType}&status=${data?.status}&instituteId=${data?.instituteId}&page=${data?.page}&size=${data?.size}`,

    //Subjects Apis
    GetSubjectsList: (data) => `${API_URL}/subjects/list`,
    CreateSubjects: `${API_URL}/subjects/create`,
    DeleteSubjects: (id) => `${API_URL}/subjects/delete?id=${id}`,

    //FAQ Categories Apis
    GetCategory: (data) => `${API_URL}/faq_category/list?page=${data?.page}&size=${data?.size}`,
    GetEnabledCategory: (data) => `${API_URL}/faq_category/public/list`,
    CreateCategory: `${API_URL}/faq_category/save`,
    DeleteCategory: (id) => `${API_URL}/faq_category/delete?id=${id}`,
    UpdateCategory: `${API_URL}/faq_category/update`,
    GetCategoryById: (id) => `${API_URL}/faq_category/get?id=${id}`,
    UpdateCategoryStatus: `${API_URL}/faq_category/update-status`,


    //FAQ 
    GetFaq: (data) => `${API_URL}/faq/list?page=${data?.page}&size=${data?.size}`,
    CreateFaq: `${API_URL}/faq/create`,
    DeleteFaq: (id) => `${API_URL}/faq/delete?id=${id}`,
    GetFaqById: (id) => `${API_URL}/faq/get?id=${id}`,
    UpdateFaq: `${API_URL}/faq/update`,
    UpdateFaqStatus: `${API_URL}/faq/update-status`,

    //Media Coverage API
    CreateMediaCoverage: `${API_URL}/media/create`,
    UpdateMediaCoverage: `${API_URL}/media/update`,
    UpdateMediaCoverageStatus: `${API_URL}/media/update/status`,
    GetMediaCoverageList: (data) => `${API_URL}/media/list?page=${data?.page}&size=${data?.size}`,
    DeleteMediaCoverage: (id) => `${API_URL}/media/delete?id=${id}`,
    GetMediaCoverageById: (id) => `${API_URL}/media/get?id=${id}`,

    //User Stories API
    CreateUserStories: `${API_URL}/story/create`,
    UpdateUserStories: `${API_URL}/story/update`,
    UpdateUserStoriesStatus: `${API_URL}/story/update/status`,
    GetUserStoriesList: (data) => `${API_URL}/story/list?page=${data?.page}&size=${data?.size}`,
    DeleteUserStories: (id) => `${API_URL}/story/delete?id=${id}`,
    GetUserStoriesById: (id) => `${API_URL}/story/get?id=${id}`,

    //How To use API
    CreateHowToUse: `${API_URL}/how-to-use/create`,
    UpdateHowToUse: `${API_URL}/how-to-use/update`,
    UpdateHowToUseStatus: `${API_URL}/how-to-use/update/status`,
    GetHowToUseList: (data) => `${API_URL}/how-to-use/list?page=${data?.page}&size=${data?.size}`,
    DeleteHowToUse: (id) => `${API_URL}/how-to-use/delete?id=${id}`,
    GetHowToUseById: (id) => `${API_URL}/how-to-use/get?id=${id}`,

    //Attachment API
    UploadAttachment: `${API_URL}/backend/file/uploadAttachment`,

    //Subscription API
    CreateSubscriptionConfig: `${API_URL}/subscription/config/create`,
    SubscriptionConfigList: (data) => `${API_URL}/subscription/config/list?page=${data?.page}&size=${data?.size}`,
    GetSubscriptionConfig: (tenure) => `${API_URL}/subscription/config/get?tenure=${tenure}`,

    //Institiute Subscription API
    CreateInstituteSubscriptionConfig: (data) => `${API_URL}/subscription/${data?.productType === "DEVELOP" ? "institute/update" : "institute/update/evolve"}`,
    InstituteSubscriptionConfigList: (data) => `${API_URL}/institute/product/history/list?instituteId=${data?.instituteId}&productType=${data?.productType}&page=${data?.page}&size=${data?.size}`,
    GetInstituteSubscriptionConfig: (data) => `${API_URL}/subscription/${data?.productType === "DEVELOP" ? "institute/develop/get" : "institute/evolve/get"}?instituteId=${data?.instituteId}`,
    GetInstituteProductList: (data) => `${API_URL}/product/institute/list?productType=${data?.productType}&page=${data?.page}&size=${data?.size}`,
    GetSubscribedUserList: (data) => `${API_URL}/subscription/user/list?page=${data?.page}&size=${data?.size}`,
    GetInstituteCandidateCountList: (data) => `${API_URL}/candidate/count-list?instituteId=${data?.instituteId}&page=${data?.page}&size=${data?.size}`,
    UpdateInstituteCandidateCount: `${API_URL}/candidate/update-count`,
    GetInstituteSubscriptionData: (data) => `${API_URL}/subscription/get/data?instituteId=${data?.instituteId}`,
    CreateInstituteTrialSubscriptionData: `${API_URL}/institute/subscription/create`,
    UpdateInstituteEvolveSubscriptionData: `${API_URL}/subscription/institute/trial/update/evolve`,
    UpdateInstituteFaceRecognitionStatus: `${API_URL}/subscription/update/face/recognition`,


    //Blog Categories Apis
    GetBlogCategory: (data) => `${API_URL}/backend/blog-category/list?page=${data?.page}&size=${data?.size}`,
    GetEnabledBlogCategory: (data) => `${API_URL}/backend/blog-category/public/list`,
    CreateBlogCategory: `${API_URL}/backend/blog-category/save`,
    DeleteBlogCategory: (id) => `${API_URL}/backend/blog-category/delete?id=${id}`,
    UpdateBlogCategory: `${API_URL}/backend/blog-category/update`,
    GetBlogCategoryById: (id) => `${API_URL}/backend/blog-category/get?id=${id}`,
    UpdateBlogCategoryStatus: `${API_URL}/backend/blog-category/update-status`,

    //Blog Apis
    GetBlog: (data) => `${API_URL}/backend/blog/list?page=${data?.page}&size=${data?.size}`,
    CreateBlog: `${API_URL}/backend/blog/create`,
    DeleteBlog: (id) => `${API_URL}/backend/blog/delete?id=${id}`,
    GetBlogById: (id) => `${API_URL}/backend/blog/get?id=${id}`,
    UpdateBlog: `${API_URL}/backend/blog/update`,
    UpdateBlogStatus: `${API_URL}/backend/blog/update-status`,

    //Entity APIS
    GetEntity: (data) => `${ML_URL}/users/spam-check/?page=${data?.page}&size=${data?.size}`,
    DeleteEntity: (id) => `${API_URL}/entity/delete?id=${id}`,
    UpdateEntity: `${ML_URL}/users/update-spam`,

    //Deleted Entity APIS
    GetDeletedEntity: (data) => `${ML_URL}/users/deleted-spam-list/?page=${data?.page}&size=${data?.size}`,
    //Faculty API
    GetFacultyList: (data) => `${API_URL}/faculty/list?searchType=${data?.searchType}&searchQuery=${data?.searchQuery}&instituteId=${data?.instituteId}`,

    // URL thumbnail APIs
    UrlScrapping: `${ML_URL}/users/ogscrapper`,

    //Curriculam Apis
    GetBoardList: (data) => `${API_URL}/board/list?page=${data.page}&size=${data.size}`,
    CreateBoard: `${API_URL}/board/save`,
    DeleteBoard: (id) => `${API_URL}/board/delete?id=${id}`,
    GetBoardClassList: (data) => `${API_URL}/board/class/subject/classwise_list?boardId=${data.boardId}`,
    CreateBoardClass: `${API_URL}/board/class/save`,
    DeleteBoardClass: (id) => `${API_URL}/board/class/delete?id=${id}`,
    GetBoardClassSubjectList: (data) => `${API_URL}/board/class/subject/list?boardId=${data.boardId}&studentClass=${data?.studentClass}`,
    CreateBoardClassSubject: `${API_URL}/board/class/subject/save`,
    DeleteBoardClassSubject: (id) => `${API_URL}/board/class/subject/delete?id=${id}`,
    GetBoardClassSubjectChapterList: (data) => `${API_URL}/board/class/subject/chapter/list?boardId=${data.boardId}&studentClass=${data?.studentClass}&subjectId=${data?.subjectId}`,
    CreateBoardClassSubjectChapter: `${API_URL}/board/class/subject/chapter/save`,
    UpdateBoardClassSubjectChapter: `${API_URL}/board/class/subject/chapter/update`,
    GetBoardClassSubjectChapter: (id) => `${API_URL}/board/class/subject/chapter/get?id=${id}`,
    DeleteBoardClassSubjectChapter: (id) => `${API_URL}/board/class/subject/chapter/delete?id=${id}`,
    GetUserDataByCustomUrl:  (customUrl) => `${API_URL}/institute/get-by-customurl?customUrl=${customUrl}`,
    GetCurriculam: (instituteId) => `${API_URL}/curriculum/get?instituteId=${instituteId}`,
    GetCurriculamSubjectList: (data) => `${API_URL}/curriculum/get/subjects?instituteId=${data?.instituteId}&boardId=${data?.boardId}&studentClass=${data?.studentClass}`,
    GetCurriculamChapter: (data) => `${API_URL}/chapter/get?subjectId=${data?.subjectId}&curriculumId=${data?.curriculumId}`,
    CreateStudentClass: `${API_URL}/studentClass/create`,
    UpdateStudentClass: `${API_URL}/studentClass/update`,
    UpdateStudentClassStatus: `${API_URL}/studentClass/update/status`,
    GetStudentClassList: (data) => `${API_URL}/studentClass/list?page=${data?.page}&size=${data?.size}`,
    GetStudentClass: (id) => `${API_URL}/studentClass/get?id=${id}`,
    GetStudentClassData: `${API_URL}/studentClass/get-enable-list`,

    GetCurriculam: (instituteId) => `${API_URL}/curriculum/get?instituteId=${instituteId}`,

    /* Individual details Apis */
    GetIndividualProfileDetailsBycustomUrl: (customUrl) => `${API_URL}/individual/get-by-customurl?customUrl=${customUrl}`,
    GetTransactionDataByUserType: (data) => `${API_URL}/transaction/list?userId=${data?.userId}&instituteId=${data?.instituteId}&creditType=${data?.creditType}&userType=${data?.userType}&page=${data?.page}&size=${data?.size}`,
    GetIndividualQuizSummaryData: (data) => `${API_URL}/quiz/summary/student/list?userId=${data?.userId}&instituteId=${data?.instituteId}&filter=${data?.filter}&page=${data?.page}&size=${data?.size}`,
    GetCandidateAnalyticsCategorywise: (data) => `${ML_URL}/users/student-view-impression?studentId=${data?.studentId}&dateString=${data?.dateString}&analyticsViewOption=${data?.analyticsViewOption}&page=${data?.page}&size=${data?.size}`,
    GetCandidateCompareAnalyticsData: (data) => `${ML_URL}/users/student-entity-comparison?studentId=${data?.studentId}&dateString=${data?.dateString}&entity=${data?.entity}&page=${data?.page}&size=${data?.size}`,
    GetCandidateAnalyticsQuizCategorywise: (data) => `${ML_URL}/users/student-quiz-categorywise-attempts?studentId=${data?.studentId}&dateString=${data?.dateString}&page=${data?.page}&size=${data?.size}`,
    GetIndividualSubscriptionHistoryList: (data) => `${API_URL}/subscription/history/list?userId=${data?.userId}`,

    EvolveSubscriptionConfigList: (data) => `${API_URL}/subscription/product/list?page=${data?.page}&size=${data?.size}`,
    CreateEvolveSubscriptionConfig: `${API_URL}/subscription/product/create`,
    GetCandidateAnalyticsQuizCategorywise: (data) => `${ML_URL}/users/student-quiz-categorywise-attempts?studentId=${data?.studentId}&dateString=${data?.dateString}&page=${data?.page}&size=${data?.size}`,    

    /* Assignment Apis */
    GetInstituteAssignmentList: (data) => `${API_URL}/assignment/institute/list?instituteId=${data?.instituteId}&studentClass=${data?.studentClass}&subjectId=${data?.subjectId}&facultyName=${data?.facultyName}&page=${data?.page}&size=${data?.size}`,
    GetInstituteStudentAssignmentList: (data) => `${API_URL}/assignment/institute/student/list?instituteId=${data?.instituteId}&username=${data?.username}&page=${data?.page}&size=${data?.size}`,
    GetAssignmentWiseStudentList: (data) => `${API_URL}/assignment/student/list_assignment_wise?instituteId=${data?.instituteId}&assignmentId=${data?.assignmentId}&username=${data?.username}&page=${data?.page}&size=${data?.size}`,
    AssignmentCompleteQuestionList: (data) => `${API_URL}/assignment/completed_question_list_for_student?assignmentId=${data?.assignmentId}&userId=${data?.userId}`,

    SubscriptionRequestList:(data) => `${API_URL}/subscription/query/list?page=${data?.page}&size=${data?.size}&productType=${data?.productType}&category=${data?.category}&tenure=${data?.tenure}&status=${data?.status}`,
    UpdateSubscriptionRequestStatus: `${API_URL}/subscription/query/update/status`,

    //contact us API
    GetContactUsList: (data) => `${API_URL}/contactus/list?status=${data.status}&page=${data.page}&size=${data.size}`,

    //Payment API
    GetPaymentList: (data) => `${API_URL}/payment/list?page=${data.page}&size=${data.size}`,
    GetTrialSubscriptionList: (data) => `${API_URL}/trial/subscription/list?page=${data.page}&size=${data.size}`,
    
    //contact us status api 
    UpdateContactUsStatus: `${API_URL}/contactus/update/status`,

    //assessment summary list
    GetAssignmentSummaryList:(data)=> `${API_URL}/assignment/user/summary?userId=${data?.userId}&instituteId=${data?.instituteId}&page=${data.page}&size=${data.size}`,

    GetReportedEntity: (data) => `${ML_URL}/users/abusive-articles?page=${data?.page}&size=${data?.size}`,
    UpdateReportedEntity: `${ML_URL}/users/delete-ignore-articles`,

    GetMerchantRequests: (data) => `${API_URL}/merchant/query/list?page=${data.page}&size=${data.size}&status=${data?.status}`,
    CreateMerchantSignUpCode: `${API_URL}/merchant/generate-code`,
    
    MerchantSignUpCodeList: (data) => `${API_URL}/merchant/code-list?page=${data.page}&size=${data.size}`,
    MerchantDeleteSignUpCode: (id) => `${API_URL}/merchant/delete?id=${id}`,
    ResendMerchantSignUpCode: (id) => `${API_URL}/merchant/resend-code?id=${id}`,
    RegenerateMerchantSignUpCode: (id) => `${API_URL}/merchant/regenerate-code?id=${id}`,

    // Product Categories Apis
    GetProductCategory: (data) => `${API_URL}/store/product-category/list?page=${data?.page}&size=${data?.size}`,
    CreateProductCategory: `${API_URL}/store/product-category/save`,
    DeleteProductCategory: (id) => `${API_URL}/store/product-category/delete?id=${id}`,
    UpdateProductCategory: `${API_URL}/store/product-category/update`,
    GetProductCategoryById: (id) => `${API_URL}/store/product-category/get?id=${id}`,
    UpdateProductCategoryStatus: `${API_URL}/store/product-category/update-status`,

    /* Role Apis */
    GetAllPermission: `${API_URL}/adminRole/get-all-permission`,
    CreateRole: `${API_URL}/adminRole/create`,
    GetUserRoleList: (data) => `${API_URL}/adminRole/list?page=${data?.page}&size=${data?.size}`,
    GetUserRoleById: (id) => `${API_URL}/adminRole/get?id=${id}`,
    UpdateUserRole: `${API_URL}/adminRole/update`,
    DeleteUserRole: (id) => `${API_URL}/adminRole/delete?id=${id}`,
    UpdateRoleStatus: `${API_URL}/adminRole/update/status`,
    GetEnableUserRoleList: (data) => `${API_URL}/adminRole/get-enable-list?page=${data?.page}&size=${data?.size}`,

    /* User Apis */
    CreateUser: `${API_URL}/adminUser/create`,
    GetUserList: (data) => `${API_URL}/adminUser/list?page=${data?.page}&size=${data?.size}`,
    GetUserById: (id) => `${API_URL}/adminUser/get?id=${id}`,
    UpdateUser: `${API_URL}/adminUser/update`,
    DeleteUser: (id) => `${API_URL}/adminUser/delete?id=${id}`,
    UpdateUserStatus: `${API_URL}/adminUser/update/status`,
    UpdateUserPassword: `${API_URL}/adminUser/update-password`,

    // ML Token summary
    GetTokenList: (data) => `${ML_URL}/users/token-list?page=${data?.page}&size=${data?.size}`,
    SubmitUpdateToken: `${ML_URL}/users/token-update`,

    GetMerchantListData: (data) => `${API_URL}/merchant/merchant-list?page=${data.page}&size=${data.size}&searchQuery=${data?.searchQuery}&searchType=${data?.searchType}`,
    GetMerchantProfileDetailsByCustomUrl: (data) => `${API_URL}/merchant/get-by-customurl?customUrl=${data}`,
    GetMerchantBankDetails: (merchantId) => `${API_URL}/bank/get/merchantId?merchantId=${merchantId}`,
    UpdateBankDetailsVerificationStatus: `${API_URL}/bank/update-status`,
    UpdateMerchantRequestStatus: `${API_URL}/merchant/query/update/status`,

    // Attempted Quiz user
    GetQuizAttamptedUserList: (data) => `${ML_URL}/users/get-${data?.pageViewType === "DATE_WISE_SUMMARY" ? "datewise" : "quizwise"}-candidates?quizId=${data?.quizId}&date=${data?.date}&page=${data?.page}&size=${data?.size}&searchString=${data?.searchString}&searchBy=${data?.searchBy}`,

    UpdateMerchantAddressStatus: `${API_URL}/merchant/update-status`,
    GetNewsCategoryList: `${ML_URL}/users/news-category-list`,
    UpdateNewsCategory: `${ML_URL}/users/news-category-update`,


    GetProductCategoryEnableList: (data) => `${API_URL}/store/product-category/public/list?page=${data?.page}&size=${data?.size}`,

    //Brand Apis
    GetBrandList: (data) => `${API_URL}/store/brand/list?page=${data?.page}&size=${data?.size}`,
    DeleteBrand: (id) => `${API_URL}/store/brand/delete?id=${id}`,
    CreateBrand: `${API_URL}/store/brand/save`,
    UpdateBrand: `${API_URL}/store/brand/update`,
    GetBrandById: (id) => `${API_URL}/store/brand/get?id=${id}`,
    GetEnabledBrand: (data) => `${API_URL}/store/brand/public/list`,

    GetMerchantConfigurationList: (data) => `${API_URL}/store/merchant-config/list?merchantId=${data?.id}&page=${data?.page}&size=${data?.size}`,
    CreateMerchantConfiguration: `${API_URL}/merchant-config/create-update`,
    GetMerchantConfigurationById: (id) => `${API_URL}/store/merchant-config/get?id=${id}`,
    GetMerchantProductListData:(data) => `${API_URL}/store/product/get-by-merchant?merchantId=${data?.merchantId}&searchQuery=${data?.searchQuery}&searchType=${data?.searchType}&brandId=${data?.brand}&categoryId=${data?.category}&page=${data?.page}&size=${data?.size}`,
    GetProductConfigurationByMerchantId: (merchantId) => `${API_URL}/store/merchant-config/get-data-by-merchantId?merchantId=${merchantId}`,

    // Merchant Product Configuration
    GetProductConfigurationHistory: (data) => `${API_URL}/store/product/get-product-history?productId=${data?.productId}&page=${data?.page}&size=${data?.size}`,
    GetProductRedemptionList: (data) => `${API_URL}/redemption/product-detail?productId=${data?.productId}&page=${data?.page}&size=${data?.size}`,

    //Merchant Redemption Request
    GetMerchantRedemptionRequestList: (data) => `${API_URL}/store/redemption/list?merchantId=${data?.merchantId}&page=${data?.page}&size=${data?.size}`,
    GetRedemptionDetailList:(data) => `${API_URL}/redemption/detail-code-wise?merchantId=${data?.merchantId}&redemptionCode=${data?.redemptionCode}&page=${data?.page}&size=${data?.size}`,

    //Shipment API
    GetMerchantShipmentDetailsList:(data)=> `${API_URL}/store/shipment/list?merchantId=${data?.merchantId}&searchType=${data?.searchType}&searchQuery=${data?.searchQuery}&page=${data?.page}&size=${data?.size}`,
    
    // Remarks API
    GetAllRemarksByEntityId: (data) => `${API_URL}/remarks/list?entityId=${data?.entityId}&page=${data?.page}&size=${data?.size}`,
    CreateRemarks: `${API_URL}/remarks/create`,
    UpdateRemarks: `${API_URL}/remarks/update`,
    GetRemarksById: (id) => `${API_URL}/remarks/get?id=${id}`,

    //Merchant Subscription API
    GetMerchantSubscriptionConfigList: (data) => `${API_URL}/merchant/subscription/history?merchantId=${data?.merchantId}&page=${data?.page}&size=${data?.size}`,
    GetMerchantSubscriptionData: (data) => `${API_URL}/merchant/subscription/get/data?merchantId=${data?.merchantId}`,
    CreateMerchantSubscriptionConfig: `${API_URL}/merchant/subscription/create`,
    CreateTrialMerchantSubscriptionConfig: `${API_URL}/merchant/trial/subscription/create`,

    //Reported Article details
    GetAbuseArticlesReportDetails: (data) => `${API_URL}/recommend/recommended/article-reported-details/?articleId=${data?.articleId}&page=${data?.page}&size=${data?.size}`,

    // Category Wise Quiz Details
    GetCategoryWiseDetailsList: (data) => `${ML_URL}/users/get-categorywise-details?date=${data?.date}&page=${data?.page}&size=${data?.size}`,
    GetPublicationList: (data)=> `${ML_URL}/users/get-publication-list?category=${data?.category}&date=${data?.date}&page=${data?.page}&size=${data?.size}`,

    //Shipment by code
    GetShipmentByShipmentCode: (shipmentCode) => `${API_URL}/store/shipment/get-by-shipment-code?shipmentCode=${shipmentCode}`,
    GetShipmentById: (id) => `${API_URL}/store/shipment/get?id=${id}`,
}
export { Apis };